import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/react/outline"

import { ChevronRightIcon } from "@heroicons/react/solid"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { formatDateTime } from "../../../util/dates"
import { upperFirstLetter } from "../../../util/text"
import {
  ListingAppointment,
  ListingAppointmentAddress,
} from "../../../types/listingAppointment"
import { photographers } from "../../../util/misc"

type Props = {
  listingAppointments: ListingAppointment[]
}

export const formatBookingAddress = (
  address: ListingAppointmentAddress
): string[] => {
  let lines: string[] = []

  lines.push(address.line1)

  if (address.line2) {
    lines.push(address.line2)
  }

  lines.push(address.city)
  lines.push(address.postcode)

  return lines
}

export const BookingsTable: React.FC<Props> = ({
  listingAppointments,
}): ReactElement => {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {listingAppointments.map((listingAppointment) => (
          <li key={listingAppointment.id}>
            <Link
              to={`/bookings/${listingAppointment.id}/`}
              className="block hover:bg-gray-50"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
                    <div className="flex flex-col gap-1 text-sm">
                      <p className="flex items-center gap-2 text-gray-500">
                        <span>
                          {formatDateTime(listingAppointment.bookingSlot)}
                        </span>
                        {listingAppointment.status === "PO" ? (
                          <span className="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                            Postponed
                          </span>
                        ) : listingAppointment.status === "CA" ? (
                          <span className="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                            Cancelled
                          </span>
                        ) : (
                          <>
                            <span
                              className={`inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium ${
                                listingAppointment.assignedTo
                                  ? "bg-blue-100 text-blue-800"
                                  : "bg-yellow-100 text-yellow-800"
                              }`}
                            >
                              {listingAppointment.assignedTo
                                ? upperFirstLetter(
                                    listingAppointment.assignedTo
                                  )
                                : "Unassigned"}
                            </span>
                            {listingAppointment.photographer && (
                              <span
                                className={`inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium ${"bg-green-100 text-green-800"}`}
                              >
                                {photographers[listingAppointment.photographer]}
                              </span>
                            )}
                          </>
                        )}
                      </p>
                      <p className="truncate font-medium text-blue-600">
                        {listingAppointment.contact?.name ||
                          `${listingAppointment.account?.firstName} ${listingAppointment.account?.lastName}`}
                      </p>
                      <p className="flex gap-1 text-gray-500">
                        <MailIcon className="w-5" />
                        {listingAppointment.contact?.email ||
                          listingAppointment.account?.email}
                      </p>
                      <p className="flex gap-1 text-gray-500">
                        <PhoneIcon className="w-5" />
                        {listingAppointment.contact?.phoneNumber ||
                          listingAppointment.account?.phoneNumber}
                      </p>
                    </div>
                    <div className="flex items-start gap-2 text-sm text-gray-800">
                      <LocationMarkerIcon className="w-5 text-gray-500" />
                      <div className="flex flex-col gap-1">
                        {formatBookingAddress(listingAppointment.address).map(
                          (line, i) => (
                            <p
                              className={i == 0 ? "font-medium" : ""}
                              key={`line-${i}`}
                            >
                              {line}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
