import { ReactElement } from "react"
import { Link } from "react-router-dom"

interface PaginationProps {
  start: number
  end: number
  total: number
  prevUrl?: string
  nextUrl?: string
}

export const Pagination: React.FC<PaginationProps> = ({
  start,
  end,
  total,
  prevUrl,
  nextUrl,
}): ReactElement => {
  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          {total === 0 ? (
            "No results"
          ) : (
            <>
              Showing <span className="font-medium">{start}</span> to{" "}
              <span className="font-medium">{end}</span> of{" "}
              <span className="font-medium">{total}</span> result
              {total !== 1 ? "s" : ""}
            </>
          )}
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <Link
          to={prevUrl || ""}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
            !prevUrl ? "pointer-events-none opacity-50" : "hover:bg-gray-50"
          }`}
        >
          Previous
        </Link>
        <Link
          to={nextUrl || ""}
          className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
            !nextUrl ? "pointer-events-none opacity-50" : "hover:bg-gray-50"
          }`}
        >
          Next
        </Link>
      </div>
    </nav>
  )
}
