import { ReactElement } from "react"
import { FilterOption } from "../global/filter-option"
import { ViewingFilters } from "../../redux/slices/viewing-list"
import { DateFilter } from "../bookings/list/date-filter"

type Props = {
  filters: ViewingFilters
  availableViewingDates: string[]
  onChange: (filters: ViewingFilters) => void
}

export const ViewingFiltersPanel: React.FC<Props> = ({
  filters,
  availableViewingDates,
  onChange,
}): ReactElement => {
  const changeStatus = (status?: string) => {
    let updatedStatus: string[] = []

    if (status) {
      updatedStatus = [status]
    }

    onChange({ ...filters, status: updatedStatus })
  }

  const changeDate = (value: string | null) => {
    onChange({ ...filters, date: value ?? undefined })
  }

  return (
    <div className="flex flex-col gap-0 bg-white text-sm text-gray-800 shadow sm:mb-6 sm:flex-row sm:gap-12 sm:rounded-md sm:px-6 lg:mb-8">
      <div className="flex items-center border-b sm:flex-1 sm:border-0">
        <span className="w-1/3 border-r p-4 font-medium sm:w-auto sm:pl-0">
          Status
        </span>
        <FilterOption
          label="All"
          checked={!filters.status}
          onToggle={() => changeStatus()}
        />
        <FilterOption
          label="Confirmed"
          checked={Boolean(filters.status?.includes("CO"))}
          onToggle={() => changeStatus("CO")}
        />
        <FilterOption
          label="Cancelled"
          checked={Boolean(filters.status?.includes("CA"))}
          onToggle={() => changeStatus("CA")}
        />
      </div>
      <div className="flex items-center justify-end sm:justify-end sm:border-0">
        <DateFilter
          value={filters.date ?? null}
          dates={availableViewingDates}
          onChange={changeDate}
        />
      </div>
    </div>
  )
}
