import {
  ArrowsExpandIcon,
  CloudIcon,
  EyeOffIcon,
  HomeIcon,
  InformationCircleIcon,
  LinkIcon,
  StarIcon,
  UserIcon,
} from "@heroicons/react/outline"

import { ChevronRightIcon } from "@heroicons/react/solid"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { PropertyOverview } from "../../types/property"
import { formatAddress } from "../../util/addresses"
import { Pagination } from "../global/pagination"

type Props = {
  properties: PropertyOverview[]
}

export const PropertyTable: React.FC<Props> = ({
  properties,
}): ReactElement => {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {properties.map((property) => (
          <li key={property.id}>
            <Link
              to={`/properties/${property.id}/`}
              className="block hover:bg-gray-50"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <div className="flex flex-col gap-2 text-sm font-medium text-blue-600 xl:flex-row xl:items-center">
                        {property.address
                          ? formatAddress(property.address, true)
                          : property.displayAddress}
                        {property.bookingId && (
                          <div className="flex">
                            <UserIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-teal-600 xl:h-4 xl:w-4" />
                            <span className="font-normal text-teal-600 xl:text-xs">
                              User Submitted
                            </span>
                          </div>
                        )}
                        {property.users && (
                          <div className="flex">
                            <LinkIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400 xl:h-4 xl:w-4" />
                            <span className="font-normal text-gray-500 xl:text-xs">
                              {property.users
                                .map(
                                  (user) => `${user.firstName} ${user.lastName}`
                                )
                                .join(", ")}
                            </span>
                          </div>
                        )}
                        {property.status === "H" && (
                          <div className="flex">
                            <EyeOffIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400 xl:h-4 xl:w-4" />
                            <span className="font-normal text-gray-500 xl:text-xs">
                              Hidden
                            </span>
                          </div>
                        )}
                        {property.featured && (
                          <div className="flex">
                            <StarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-600 xl:h-4 xl:w-4" />
                            <span className="font-normal text-green-600 xl:text-xs">
                              Featured
                            </span>
                          </div>
                        )}
                      </div>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <InformationCircleIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="mono truncate">{property.id}</span>
                      </p>
                    </div>
                    <div className="hidden items-center md:flex">
                      <ul className="grid grid-cols-3 gap-8 text-sm text-gray-500">
                        <li className="flex md:w-[120px] lg:w-[160px]">
                          <CloudIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {property.attributes.bedrooms
                            ? `${property.attributes.bedrooms} Bedroom${
                                property.attributes.bedrooms > 1 ? "s" : ""
                              }`
                            : "Studio"}
                        </li>
                        <li className="flex md:w-[120px] lg:w-[160px]">
                          {property.attributes.buildingType && (
                            <>
                              <HomeIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {property.attributes.buildingType}
                            </>
                          )}
                        </li>
                        <li className="flex md:w-[120px] lg:w-[160px]">
                          {property.attributes.interiorSize && (
                            <>
                              <ArrowsExpandIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {property.attributes.interiorSize.toLocaleString()}{" "}
                              sq ft
                            </>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      {/* <Pagination
        start={1}
        end={properties.length}
        total={properties.length}
        enablePrev={false}
        enableNext={false}
      /> */}
    </div>
  )
}
