import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getEndpoint } from "../../util/api"

import { ListResponse } from "../../ostrich/rpc/users/viewing_requests/v2_pb"

export interface BaseFilters {
  searchQuery?: string
  page?: number
}

export interface OfferFilters extends BaseFilters {
  status?: string[]
  includeUnverifiedUsers?: boolean
}

export const decodeFilters = (searchParams: URLSearchParams): OfferFilters => {
  const filters: OfferFilters = {}

  const status = searchParams
    .get("status")
    ?.split(",")
    .filter((s) => s.length)

  if (status?.length) {
    filters.status = status
  }

  const includeUnverifiedUsers = searchParams.get("includeUnverifiedUsers")

  if (includeUnverifiedUsers) {
    filters.includeUnverifiedUsers = true
  }

  const search = searchParams.get("search")

  if (search) {
    filters.searchQuery = search
  }

  const page = searchParams.get("page")

  if (page) {
    filters.page = parseInt(page)
  }

  return filters
}

export const encodeFilters = (filters: OfferFilters): URLSearchParams => {
  const params = new URLSearchParams()

  if (filters.status?.length) {
    params.set("status", filters.status.join(","))
  }

  if (filters.includeUnverifiedUsers) {
    params.set("includeUnverifiedUsers", "true")
  }

  if (filters.searchQuery) {
    params.set("search", filters.searchQuery)
  }

  if (filters.page) {
    params.set("page", filters.page.toString())
  }

  return params
}

const initialState: ListResponse = {
  viewingRequests: [],
  pagination: {
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 10,
  },
}

export const fetchOffers = createAsyncThunk(
  "offerList/fetchOffers",
  async (filters: OfferFilters, thunkAPI) => {
    const encodedFilters = encodeFilters(filters)

    const response = await fetch(
      `${getEndpoint()}/offers/?${encodedFilters.toString()}`,
      {
        cache: "no-cache",
        credentials: "include",
      }
    )

    const json = (await response.json()) as ListResponse

    return json
  }
)

export const offerListSlice = createSlice({
  name: "offerList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOffers.fulfilled, (state, action) => {
      state.viewingRequests = action.payload.viewingRequests
      state.pagination = action.payload.pagination || initialState.pagination
    })
  },
})

export const offerListReducer = offerListSlice.reducer
