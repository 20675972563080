import { ReactElement, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  OfferFilters,
  decodeFilters,
  encodeFilters,
  fetchOffers,
} from "../../redux/slices/offer-list"
import { Container } from "../global/container"
import { Header } from "../global/header"
import { OfferFiltersPanel } from "./offer-filters"
import {
  CurrencyPoundIcon,
  HomeIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline"
import { ItemDates } from "../global/tables/item-dates"
import { Table } from "../global/tables/table"
import { ViewingRequestListItem } from "../../ostrich/rpc/users/viewing_requests/v2_pb"
import { OfferComputedStatusLabel } from "./computed-status-label"

export const ViewingRequestRow: React.FC<{
  viewingRequestListItem: ViewingRequestListItem
}> = ({ viewingRequestListItem }): ReactElement => {
  return (
    <div className="h-12 min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
      <div>
        <p className="flex items-center gap-2 truncate text-sm font-medium text-blue-600">
          {viewingRequestListItem.buyer?.abbreviatedName}
          {viewingRequestListItem.status &&
            viewingRequestListItem.buyer?.verifiedStripe !== "VE" && (
              <span
                key="unverified"
                className="inline-flex items-center rounded-full bg-orange-100 px-2.5 py-0.5 text-xs font-medium text-orange-800"
              >
                Unverified
              </span>
            )}
          <OfferComputedStatusLabel
            key="status"
            offer={viewingRequestListItem}
            style="large"
          />
          {viewingRequestListItem.buyerContacted && (
            <span
              key="contacted"
              className="inline-flex items-center rounded-full bg-cyan-100 px-2.5 py-0.5 text-xs font-medium text-cyan-800"
            >
              Buyer Contacted
            </span>
          )}
        </p>
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <InformationCircleIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          <span className="mono truncate">{viewingRequestListItem.id}</span>
        </p>
      </div>
      <div className="hidden md:block">
        <div>
          <ul className="flex flex-col gap-2 text-sm text-gray-500">
            <li className="flex">
              <HomeIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {viewingRequestListItem.property?.address}
            </li>
            <li className="flex">
              <CurrencyPoundIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {viewingRequestListItem.offer
                ? `£${viewingRequestListItem.offer?.toLocaleString()}`
                : "Buying agent"}
            </li>
          </ul>
        </div>
      </div>
      <div>
        <ItemDates
          item={{
            dateCreated: viewingRequestListItem.dateCreated,
            dateUpdated: viewingRequestListItem.dateUpdated,
          }}
        />
      </div>
    </div>
  )
}

export const OfferList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<OfferFilters>({})

  const { viewingRequests, pagination } = useAppSelector(
    (state) => state.offerList,
  )

  useEffect(() => {
    const filters = decodeFilters(searchParams)
    dispatch(fetchOffers(filters))
    setFilters(filters)
  }, [dispatch, searchParams])

  const handleChangeFilters = (newFilters: OfferFilters) => {
    const hasOtherFilterChanges = Object.keys(newFilters).some(
      (key) =>
        key !== "page" &&
        key !== "searchQuery" &&
        newFilters[key as keyof OfferFilters] !==
          filters[key as keyof OfferFilters],
    )

    const finalFilters = hasOtherFilterChanges
      ? { ...newFilters, page: 1 }
      : newFilters

    setSearchParams(encodeFilters(finalFilters))
  }

  return (
    <>
      <Header title="Offers" />
      <Container>
        <OfferFiltersPanel filters={filters} onChange={handleChangeFilters} />
        {viewingRequests && (
          <Table
            items={viewingRequests}
            getItemUrl={(viewingRequestListItem: ViewingRequestListItem) =>
              `/offers/${viewingRequestListItem.id}/`
            }
            paginationData={pagination}
            baseUrl="/offers"
            searchQuery={filters.searchQuery}
            onSearch={(value: string) => {
              setSearchParams(
                encodeFilters({ ...filters, searchQuery: value, page: 1 }),
              )
            }}
          >
            {(viewingRequestListItem: ViewingRequestListItem) => (
              <ViewingRequestRow
                viewingRequestListItem={viewingRequestListItem}
              />
            )}
          </Table>
        )}
      </Container>
    </>
  )
}
